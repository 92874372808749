const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/place"],
    exact: false,
    component: "Place",
  },
  {
    path: ["/blog"],
    exact: false,
    component: "Blog",
  },
];

export default routes;
