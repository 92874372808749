import React, { useEffect, useState } from 'react';
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { useRecoilState } from 'recoil';
import { languageState } from '../../common/global';
import axios from 'axios';

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";


interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const [language, setLanguage] = useRecoilState(languageState);
  const [visits, setVisits] = useState(0);

  const badgeColor = "blue";
  const altText = `Visits ${visits}`;

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
    document.body.setAttribute('lang', language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get('https://www.yyo.co.kr:5353/hit');
        let metricValue = 0;
        let count: number = response.data.rowCount;
        for (let i = 0; i < count; i++) {
          metricValue += Number(response.data.rows[i].metricValues[0].value);
        }

        setVisits(metricValue);
      } catch (error) {
        console.error('Error fetching visits:', error);
      }
    };

    fetchVisits();
  }, []);

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Label>{t("Contact")}</Label>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:cto@yyo.co.kr">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Label>{t("Policy")}</Label>
              <Large to="/" left="true">
                {t("Application Security")}
              </Large>
              <Large left="true" to="/">
                {t("Software Principles")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Support Center")}
              </Large>
              <Large left="true" to="/">
                {t("Customer Support")}
              </Large>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Label>{t("Address")}</Label>
              <Para>{t("YYO address")}</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Label>{t("Company")}</Label>
              <Large left="true" to="/place">
                {t("Place")}
              </Large>
              <Large left="true" to="/blog">
                {t("Tech. Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-kingdom.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("ko")}>
                  <SvgIcon
                    src="korean.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://github.com/YYO-tech"
                src="github.svg"
              />
              <SocialLink
                href="https://theyyo.slack.com"
                src="slack.svg"
              />
              <a href="/">
                <img
                  src={`https://img.shields.io/badge/Visits-${visits}-${badgeColor}`}
                  alt={altText}
                />
              </a>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
